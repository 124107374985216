// Modeled after addAchCompanyModalCtrl.js
import { CompanyPositivePayServices } from '@treasury/domain/backoffice/requests/company-configuration/company-positive-pay.js';
import { select } from '@treasury/policy/backoffice-report.js';

angular.module('backOffice').controller('PositivePayAccountSearchModalController', positivePayAccountSearchModalController);

positivePayAccountSearchModalController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    '$modalInstance',
    'apiFeatureName',
    'displayedAccounts',
    'posPay',
];

function positivePayAccountSearchModalController(
    $scope,
    $state,
    $stateParams,
    $q,
    $modalInstance,
    apiFeatureName,
    displayedAccounts,
    posPay,
) {
    $scope.addAccount = addAccount;
    $scope.cancel = cancel;
    $scope.errorMessage = null;
    $scope.foundAccounts = [];
    $scope.isAccountSelected = isAccountSelected;
    $scope.search = search;
    $scope.isSearching = false;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    
    function addAccount() {
        const selectedAccount = $scope.foundAccounts.filter(account => account.isUserSelected)[0];
        if (isAccountAlreadyConfigured(selectedAccount.accountNumber)) {
            $scope.errorMessage = 'This account has already been configured.';
        } else {
            $modalInstance.close(selectedAccount);
        }
    }

    function cancel() {
        $modalInstance.close();
    }

    function isAccountAlreadyConfigured(accountNumber) {
        return displayedAccounts.some(account => account.accountNumber === accountNumber);
    }

    function isAccountSelected() {
        return $scope.foundAccounts.some(account => account.isUserSelected);
    }

    async function search(accountNumber){  
        $scope.errorMessage = null;
        if(accountNumber.length < 1) {  
            $scope.foundAccounts = [];  
            $scope.$apply();  
            return;  
        }
        $scope.isSearching = true;
        try {
            const response = await CompanyPositivePayServices.searchAccountsByNumber($stateParams.id, accountNumber);
            const accounts = response.find(({featureName}) => featureName === apiFeatureName)?.featureParticipations || [];
            accounts.forEach(account => {
                account.isUserSelected = true;
            });
            $scope.foundAccounts = angular.copy(accounts);
        }
        catch(e){
            $scope.errorMessage = e;
        }
        finally {
            $scope.isSearching = false;
        }
        $scope.$apply();
    }

    function selectOrDeselectAll() {
        angular.forEach($scope.foundAccounts, item => {
            item.isUserSelected = $scope.obj.selectAll;
        });
    }
}