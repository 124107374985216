import { DiContainer } from '@jack-henry/frontend-utils/di';
import CompanyInstitutionOptions from '@treasury/domain/backoffice/mappings/company-configuration/company-positive-pay.js';
import { CompanyPositivePayServices } from '@treasury/domain/backoffice/requests/company-configuration/company-positive-pay.js';
import InstitutionPositivePayRequests from '@treasury/domain/backoffice/requests/institution-configuration/institution-positive-pay-requests.js';
import { CompanyProductFeatures } from '@treasury/domain/backoffice/requests/product-features/product-features.js';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { html } from 'lit';

/**
 * @typedef { Object } PosPayError
 * @property { string } posPayType
 * @property { string } accountId
 * @property { string } message
 */

/**
 * @typedef { Object } PosPayResponse
 * @property { PosPayError[] } validationErrors
 */

function darkCustomizeArpController(
    $scope,
    $state,
    $stateParams,
    toaster,
    $timeout,
    entitlementsService,
    $q,
    TmDi
) {
    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function setUpCheck() {
        if ($scope.isCheckVisible) {
            $scope.checkEnabled = $scope.posPay.areCheckExceptionsEnabled();
            $scope.checkAccounts = $scope.posPay.getCheckExceptions();
        }
    }

    function setUpAch() {
        if ($scope.isACHVisible) {
            $scope.workAchException = $scope.posPay.areAchExceptionsEnabled();
            $scope.achExceptionAccounts = $scope.posPay.getAchExceptions();
            $scope.approveCount = $scope.posPay.getAchApprovers();
        }
    }

    async function isFiAchFilterEnabled() {
        const options = $scope.posPay.institutionOptions.institutionConfig;
        const isAllowManageAchFilters = options['PositivePay.ACHFilterManagement.AllowManageAchFilters'] === '1';
        const hasACHFilterManagement = await hasEntitlement(
            'Feature.PositivePay.ACHFilterManagement'
        );
        // Can't check saved state of options. They want to toggle the visibility in
        // the temporary state of the form in the UI.
        const workExceptionsAreOn =
            $scope.posPay.companyFeatures.companyConfiguration['PositivePay.WorkAchExceptions'] ===
            '1';
        return hasACHFilterManagement && isAllowManageAchFilters && workExceptionsAreOn;
    }

    function setUpAchFilterRules() {
        if ($scope.isACHFiltersVisible) {
            $scope.showFilters = $scope.posPay.areAchFiltersEnabled();
            $scope.achFilterAccounts = $scope.posPay.getAchFilterAccounts();
            $scope.approveCount = $scope.posPay.getAchApprovers();
        }
    }

    function setUpSubstituteProduct() {
        /*
        All config will come out of inst options API
        not sure about the contract since its not set up for the FI the ff is on
        Tony is not sure product wants to even support this, 
        so need to get yes/no before dealing with this headache...stubbing for now
        
       */
        $scope.isSubstituteProductVisible = $scope.posPay.isSubstituteProductVisible();
        if ($scope.isSubstituteProductVisible) {
            /* 
            $scope.ssoProductName = 'name of product'
            $scope.isSubstituteProductEnabled = true/false (1/0)
            $scope.ssoClientId = '10104'
            $scope.ssoLandingPageUrl = 'sso.google.com'
            API Response looks like this in r19554 dev 
            {
                "name":"PositivePay.SubstituteProductConfig.AllowSubstituteProduct",
                "value":"0"
            },
            {
                "name":"PositivePay.SubstituteProductConfig.SsoClientId",
                "value":"8351468588024545"
            },
            {
                "name":"PositivePay.SubstituteProductConfig.SsoLandingPageUrl",
                "value":"https://www.centrixsecure1.com/JHATellerPositivePayDemo/Pages/LoginSSO.aspx"
            },
            {
                "name":"PositivePay.SubstituteProductConfig.SsoProductName",
                "value":"Centrix"
            },
            */
        }
    }

    function setUpIssuedItems() {
        $scope.areIssuedItemsVisible = $scope.posPay.areIssuedItemsVisible();
        if ($scope.areIssuedItemsVisible) {
            $scope.issuedEnabled = $scope.posPay.isIssuedItemsEnabled();
        }
    }

    function setUpActivityReview() {
        $scope.isAllowCorrectionRequestVisible = $scope.posPay.isAllowCorrectionRequestVisible();
        if ($scope.isAllowCorrectionRequestVisible) {
            $scope.allowCorrectionRequest = $scope.posPay.isAllowCorrectionRequestEnabled();
        }
        $scope.isAllowReturnReasonVisible = $scope.posPay.isAllowReturnReasonVisible();
        if ($scope.isAllowReturnReasonVisible) {
            $scope.allowReturnReason = $scope.posPay.isAllowReturnReasonEnabled();
        }
    }

    function isAchInFocus() {
        return $scope.isACHVisible && $scope.activeTab === 'ach';
    }

    function isAchFiltersInFocus() {
        return $scope.isACHFiltersVisible && $scope.activeTab === 'ach-filters';
    }

    function isCheckInFocus() {
        return $scope.isCheckVisible && $scope.activeTab === 'check';
    }

    async function update(shouldSwitchTabs = false) {
        if ($scope.posPay.hasLoaded) {
            $q.resolve(isFiAchFilterEnabled()).then(isACHFiltersVisible => {
                $scope.isACHFiltersVisible = isACHFiltersVisible;
                $scope.isCheckVisible = $scope.posPay.areCheckExceptionsVisible();
                $scope.isACHVisible = $scope.posPay.areAchExceptionsVisible();
                setUpIssuedItems();
                setUpSubstituteProduct();
                setUpActivityReview();
                $scope.hasChanged = $scope.posPay.hasChanged();
                if (!shouldSwitchTabs) { setInitialTabs() }
                setUpTab($scope.activeTab);
            });
        } else {
            $timeout(() => {
                update();
            }, 1000);
        }
    }

    function setInitialTabs() {
        if ($scope.isCheckVisible) {
            setActiveTab('check');
        } else if ($scope.isACHVisible) {
            setActiveTab('ach');
        } else if ($scope.isACHFiltersVisible) {
            setActiveTab('ach-filters');
        }
    }

    function setUpTab(tab) {
        switch(tab) {
            case 'check':
                setUpCheck();
                break;
            case 'ach':
                setUpAch();
                setUpAchFilterRules();
                break;
            case 'ach-filters':
                setUpAchFilterRules();
                break;
            default:
                throw new Error('Invalid tab name');
        }
    }

    async function setActiveTab(tab) {
        $scope.activeTab = tab;
        if ($scope.posPay.companyAccounts.isPerformanceEnabled) {
            if (tab === 'check' && $scope.posPay.getCheckExceptions() && $scope.posPay.getCheckExceptions().length > 0) { return }
            if (tab === 'ach' && $scope.posPay.getAchExceptions() && $scope.posPay.getAchExceptions().length > 0) { return }
            if (tab === 'ach-filters' && $scope.posPay.getAchFilterAccounts() && $scope.posPay.getAchFilterAccounts().length > 0) { return }
            const promise = $scope.posPay.companyAccounts.fetchExceptions(tab);
            $q.resolve(promise).then(() => { 
                setUpTab(tab);
            });    
        } else {
            setUpTab(tab);
        }
    }

    function handleAddAccount(account) {
         function getAccountType(){
             switch($scope.activeTab) {
                 case 'ach':
                     return 'WorkACHExceptions' ;
                 case 'ach-filters':
                     return 'ACHFilterManagement' ;
                 default:
                    return  'WorkCheckExceptions';
             }
       }
       
        const accountType = getAccountType();
        $scope.posPay.companyAccounts.changeAccountsOnSave(accountType, account);
        $scope.save();
    }

    async function init() {
        const featureFlagService = await TmDi.getAsync(FeatureFlagService);
        const companyId = $stateParams.id;
        Promise.all([
            CompanyProductFeatures.hasPositivePay(companyId),
            CompanyPositivePayServices.getCompany(companyId),
        ]).then(response => {
            const [productIsAlreadyEnabled, company] = response;
            $scope.requiredEntitlement = productIsAlreadyEnabled;
            $scope.posPay = new CompanyInstitutionOptions(company, featureFlagService);
            $scope.companyName = company.name;
            update();
        });
    }

    async function toggleFeature(key) {
        $scope.addArpProductForm.$setDirty();
        await $scope.posPay.toggle(key);
        update(true);
    }

    function changeApprovers(newValue) {
        $scope.posPay.updateAchApprovers(newValue);
        update();
    }

    function toggleAccount(key, accountId, warningMessage) {
        $scope.posPay.updateAccount(key, accountId);
        $scope[`${key}Warnings`][accountId] = warningMessage;
    }

    function requiredEntitlements() {
        return $scope.requiredEntitlement === true ? 'Edit Product Feature' : 'Add Product Feature';
    }

    init();

    function validateSaveResponse(response) {
        if (response.validationErrors) {
            return response.validationErrors.length === 0;
        }
        if (response.responseDetails) {
            return response.responseDetails.length === 0;
        }
        return false;
    }

    async function getDialogService() {
        return (await DiContainer.getInstance()).get(OmegaDialogService);
    }

    /**
     *
     * @param { boolean } valid
     * @param { string | import('lit').TemplateResult} message
     */
    async function showSaveErrorDialog(valid, message) {
        const messageType = valid ? 'completed but' : 'failed';
        const dialogService = await getDialogService();
        const dialogHandle = dialogService.open(
            html`The configuration save operation ${messageType} with the following validation
                errors:<br />${message}`,
            'Positive Pay Configuration',
            {
                maxWidth: 450,
                buttons: {
                    [OmegaDialogExitReason.Cancel]: null,
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Ok',
                    },
                },
            }
        );
        return $q.resolve(dialogHandle.closed);
    }

    function getTypeMapping(posPayType) {
        switch (posPayType) {
            case 'AchFilterManagement':
                return 'achFilterException';
            case 'CheckExceptions':
                return 'checkException';
            case 'AchExceptions':
                return 'achException';
            default:
                return '';
        }
    }

    /**
     * @param { TmApiError | PosPayResponse  } response
     */
    function handleInvalidSaveResponse(response) {
        if (response instanceof TmApiError) {
            const subErrors = response.subErrors || [];
            const message = subErrors.map(
                error => html`<br /><b>${error.responseCode}:</b> ${error.responseMessage}`
            );

            return showSaveErrorDialog(false, message);
        }

        if (response.validationErrors) {
            response.validationErrors.forEach(error => {
                toggleAccount(getTypeMapping(error.posPayType), error.accountId, error.message);
            });
        } else {
            return showSaveErrorDialog(false, 'An unknown error occurred.');
        }
    }

    function save() {
        $scope.isLoading = true;
        try {
            $q.resolve($scope.posPay.save()).then(response => {
                if (validateSaveResponse(response)) {
                    toaster.save('Positive Pay');
                } else {
                    handleInvalidSaveResponse(response);
                }
                $q.resolve(update(true)).then(() => {
                    $scope.addArpProductForm.$setPristine();
                    $scope.isLoading = false;
                });
            });
        } catch (e) {
            handleInvalidSaveResponse(e);
        }
    }

    function goBack() {
        $state.go('company', { id: $stateParams.id });
    }

    $scope.isAchInFocus = isAchInFocus;
    $scope.isCheckInFocus = isCheckInFocus;
    $scope.isAchFiltersInFocus = isAchFiltersInFocus;
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.toggleFeature = toggleFeature;
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.setActiveTab = setActiveTab;
    $scope.handleAddAccount = handleAddAccount;
    $scope.approvers = ['None', '1', '2', '3'];
    $scope.isCheckVisible = false;
    $scope.isACHVisible = false;
    $scope.isACHFiltersVisible = false;
    $scope.isSubstituteProductVisible = false;
    $scope.areIssuedItemsVisible = false;
    $scope.isAllowCorrectionRequestEnabled = false;
    $scope.isAllowReturnReasonEnabled = false;
    $scope.changeApprovers = changeApprovers;
    $scope.allowIssuedItemsActivityInfo =
        'Activation of this configuration will allow users to review the Issued Items Activity listing.';
    $scope.hasAchFilterManagementEntitlement = hasEntitlement(
        'Feature.PositivePay.ACHFilterManagement'
    );
    $scope.hasReturnReasonEntitlement = hasEntitlement('Feature.PositivePay.ReturnReason');
    $scope.isLoading = false;
    $scope.checkExceptionWarnings = {};
    $scope.achExceptionWarnings = {};
    $scope.achFilterExceptionWarnings = {};
}

window.angular
    .module('backOffice')
    .controller('darkCustomizeArpController', darkCustomizeArpController);

darkCustomizeArpController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'toaster',
    '$timeout',
    'entitlementsService',
    '$q',
    'TmDi',
];
